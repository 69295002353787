
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import InsurerPlanModel from "@/api/insurer_plan_app.model";
import corporateClient from "@/api/corporate_client_app.model";
import SolutionModel from "@/api/solution.model";
import CustomSelect from "./Select.vue";
import CustomButton from "./Button.vue";
import { BOverlay } from "bootstrap-vue";
import { showErrorAlert, showSuccessAlert } from "@/helpers";

@Component({
	components: {
		CustomSelect,
		CustomButton,
		BOverlay,
	},
})
export default class CorporateClientAndPlans extends Vue {
	@Prop({ default: () => ({}) }) data!: any;
	@Prop({ default: () => ({}) }) relationData!: any;
	@Prop({ default: () => null }) updateData: Function;

	localCorporateClient: any = [];
	localPlans: any[] = [];
	localCorporateClientOptions = [];
	localPlansOptions = [];
	isLoading = false;

	async mounted() {
		await this.loadCorporateClients();
		await this.loadPlans();
	}

	@Watch("data", { deep: true })
	changeLocalProperties() {
		this.localCorporateClient = this.data.corporateClient ? [this.data.corporateClient] : [];
		this.localPlans = this.data.plans;
	}

	@Watch("localCorporateClient", { deep: true })
	changeOptions() {
		this.loadCorporateClients();
		this.loadPlans();
	}

	get isDisabledSaveButton() {
		return !this.localCorporateClient || !this.localPlans || !this.localPlans.length;
	}

	async loadPlans() {
		this.isLoading = true;
		try {
			let queryInsurerPlans = [] as any;
			let corporateClientIds = [] as any;
			if (this.localCorporateClient?.length) {
				queryInsurerPlans = this.localCorporateClient.map((corporateClientId: any) => {
					return { "corporateClient.id": { $cont: corporateClientId.id } };
				});

				queryInsurerPlans = this.localCorporateClient.map((corporateClientId: string) => corporateClientId);
				corporateClientIds = this.localCorporateClient.map((corporateClient: any) => corporateClient.id);
			}

			const plans = await InsurerPlanModel.search({
				page: 1,
				limit: 0,
				corporateClientIds: corporateClientIds.length ? JSON.stringify(corporateClientIds) : undefined,
			});

			this.localPlansOptions = plans.data.map((plan: any) => {
				return {
					name: `${plan.name} - ${plan.corporateClient.name}`,
					value: {
						insurerPlan: {
							id: plan.id,
							name: plan.name,
							description: plan.description,
							corporateClient: { id: plan["corporateClient"]["id"], name: plan["corporateClient"]["name"] },
						},
					},
				};
			});
		} catch (error) {
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	}

	async loadCorporateClients() {
		try {
			const corporateClients = await corporateClient.search({});
			this.localCorporateClientOptions = corporateClients.data.reduce((finalCorporate: any, corporateClient: any) => {
				if (
					this.data.corporateClient?.id === corporateClient.id ||
					!this.relationData["corporateAndPlans"]?.filter(
						(currentCorporateClient: any) => currentCorporateClient.id === corporateClient.id,
					).length
				) {
					finalCorporate.push({
						name: corporateClient.name,
						value: { id: corporateClient.id, name: corporateClient.name },
					});
				}

				return finalCorporate;
			}, []);
		} catch (error) {
			console.error(error);
		}
	}

	removePlans() {
		this.savePlans("", true);
	}

	async savePlans(event: any, isRemove = false) {
		try {
			this.isLoading = true;
			this.data.plans?.forEach((selectedPlan: any) => {
				const planIndex = this.relationData.solutionInsurerPlans.findIndex(
					(plan: any) => selectedPlan.insurerPlan.id === plan.insurerPlan.id,
				);
				this.relationData.solutionInsurerPlans.splice(planIndex, 1);
			});
			const plansToSend = isRemove
				? [...this.relationData.solutionInsurerPlans]
				: this.relationData.solutionInsurerPlans
				? [...this.localPlans, ...this.relationData.solutionInsurerPlans]
				: [...this.localPlans];

			plansToSend.forEach((solutionPlan: any) =>
				Object.keys(solutionPlan).forEach((key: string) => {
					if (key !== "insurerPlan") {
						delete solutionPlan[key];
					}
				}),
			);
			if (this.$route.name !== "perfilCliente" && this.$route.params.id !== "novo") {
				const response = await SolutionModel.update(this.$route.params.id, {
					solutionInsurerPlans: plansToSend,
					"category.id": this.relationData["category.id"],
				});

				this.relationData.solutionInsurerPlans = response.solutionInsurerPlans;
			} else {
				this.relationData.solutionInsurerPlans = plansToSend;
			}

			showSuccessAlert("Planos salvos com sucesso");
			this.$emit("onChange");
			this.$emit("onSendFormSuccess");
		} catch (error: any) {
			console.error(error);
			showErrorAlert(error?.message);
		} finally {
			this.isLoading = false;
		}
	}
}

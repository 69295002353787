var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('BaseModal',{attrs:{"withBackButton":true,"isOpen":_vm.modalNewQualificationIsOpen,"onModalClosed":_vm.closedModalNewQualification,"backButtonText":"Cancelar","title":"Criar nova qualificação"}},[_c('BOverlay',{staticClass:"flex flex-col gap-5",attrs:{"show":_vm.isLoading}},[_c('CustomInput',{attrs:{"label":"Nome","placeholder":"Digite o nome da nova qualificação","required":true},model:{value:(_vm.nameNewQualification),callback:function ($$v) {_vm.nameNewQualification=$$v},expression:"nameNewQualification"}}),_c('CustomButton',{attrs:{"disabled":!_vm.nameNewQualification,"onClick":_vm.saveNewQualification}},[_vm._v("Criar qualificação")])],1)],1),_c('b-overlay',{staticClass:"h-full flex flex-col overflow-auto scroll",attrs:{"show":_vm.isLoading}},[(_vm.currentId !== 'novo' && !_vm.generalLoading)?_c('entity-crud',_vm._b({attrs:{"tableVariant":"secondary"}},'entity-crud',{
				model: _vm.model,
				title: _vm.title,
				tableColumns: _vm.tableColumns,
				formColumns: _vm.formColumns,
				onInputChange: _vm.onInputChange,
				defaultEntity: _vm.defaultEntity,
				withEntityHeader: _vm.withEntityHeader,
				withPostalCode: _vm.withPostalCode,
				formStyleVariant: _vm.formStyleVariant,
				formContainerVariant: _vm.formContainerVariant,
				cleanFormKeys: _vm.cleanFormKeys,
				onLoadingEntity: _vm.onLoadingEntity,
				isReadOnly: _vm.isReadOnly,
				exportColumns: _vm.exportColumns,
			},false)):(_vm.currentId === 'novo')?_c('div',{staticClass:"h-full flex flex-col gap-8"},[_c('CustomButton',{staticClass:"mb-2 self-start",attrs:{"variant":"tertiary","icon":"arrow-back.svg","iconStyle":"h-4 w-4 mr-1","onClick":_vm.onBack}},[_vm._v(" Voltar ")]),_c('InclusionType',{attrs:{"creationComponent":_vm.creationComponent,"title":"Solução","model":_vm.solutionModel,"linkDownload":_vm.templateDownload}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
































































import { translateSolutionStatus, translateSpecialties } from "@/interfaces/solution";
import { Component, Vue, Watch } from "vue-property-decorator";
import EntityCrud from "../entity/EntityCrud.vue";
import EntityForm from "../entity/EntityForm.vue";
import needModel from "../../api/need.model";
import qualificationModel from "../../api/qualification.model";
import QualificationModel from "@/api/qualification.model";
import SolutionModel from "../../api/solution.model";
import SolutionCategoryModel from "../../api/solution_category.model";
import { BOverlay } from "bootstrap-vue";
import { translateGender } from "@/interfaces/user";
import CustomButton from "@/layouts/components/Button.vue";
import InclusionType from "../InclusionType.vue";
import { spreadsheetTemplates } from "@/helpers/templateImport";
import CorporateClientAndPlans from "@/layouts/components/CorporateClientAndPlans.vue";
import { IQualification } from "@/interfaces/qualification";
import BaseModal from "@/layouts/components/BaseModal.vue";
import CustomInput from "@/layouts/components/Input.vue";
import clientModel from "@/api/client.model";
import debounce from "debounce";
import enterpriseModel from "@/api/enterprise.model";
import { Client } from "@/entities/client";

@Component({
	components: {
		EntityCrud,
		EntityForm,
		BOverlay,
		CustomButton,
		InclusionType,
		BaseModal,
		CustomInput,
	},
})
export default class Solutions extends Vue {
	model: any = SolutionModel;
	withEntityHeader = true;
	withPostalCode = true;
	formStyleVariant = "primary";
	corporateClientId = "";
	currentCategoryId = "";
	isLoading = false;
	generalLoading = true;
	isReadOnly = true;
	solutionModel = SolutionModel;
	templateDownload = spreadsheetTemplates.solutions;
	modalNewQualificationIsOpen = false;
	nameNewQualification = "";

	debouncedClientSearch = debounce(this.searchClient, 300);
	debouncedEnterpriseSearch = debounce(this.searchEnterprise, 300);

	exportColumns = [
		{ key: "category", kind: "relation", relationType: "one-to-many" },
		{ key: "name" },
		{ key: "description" },
		{ key: "isIndividualForClient" },
		{ key: "examOrDoctorName" },
		{ key: "cnpj" },
		{ key: "isFavorite", onTransform: (value: number) => (value ? "Sim" : "Não") },
		{
			key: "address.postCode",
		},
		{ key: "address.street" },
		{ key: "address.number" },
		{ key: "address.complement" },
		{ key: "address.neighborhood" },
		{ key: "address.city" },
		{ key: "address.state" },
		{
			key: "corporateClient.id",
			name: "Cliente corporativo",
			getInformationOtherKey: true,
			otherKey: "solutionInsurerPlans",
			relationKeyId: "insurerPlan.corporateClient.id",
			relationKeyName: "insurerPlan.corporateClient.name",
			onTransform: this.formatReadOnlyCorporateClient,
		},
		{
			key: "solutionInsurerPlans",
			name: "Planos de saúde",
			onTransform: this.formatReadOnlyInsurerPlan,
		},
		{
			key: "solutionNeeds",
			name: "Necessidades",
			onTransform: this.formatReadOnlyNeed,
		},
		{
			key: "solutionQualifications",
			name: "Qualificações",
			onTransform: this.formatReadOnlyQualification,
		},
		{ key: "availabilities" },
		{ key: "phoneNumber" },
		{ key: "attachmentUrl" },
		{ key: "gender", onTransform: translateGender },
		{ key: "specialties", onTransform: this.formatReadOnlySpecialties },
		{ key: "subcategory" },
		{ key: "subcategoryAttachmentUrl", name: "Anexo Sub Categoria" },
		{ key: "subcategoryDescription", name: "Sub Categoria Descrição" },
	];

	cleanFormKeys = {
		deleteKeys: ["corporateClient.id", "id", "corporateAndPlans", "solutionInsurerPlans"],
		holdKeys: ["category.id", "isIndividualForClient.id"],
	};

	title = "Solução";
	kind = "editionForm";
	formContainerVariant = "primary";
	titleKind = "secondary";
	tableColumns: any[] = [
		{
			key: "status",
			kind: "customTag",
			styleTags: {
				CONFIRMED: "primary",
				REFUSED: "secondary",
				VALIDATE: "tertiary",
				INACTIVATED: "quaternary",
			},
			options: [
				{ name: "Validar", value: "VALIDATE" },
				{ name: "Confirmado", value: "CONFIRMED" },
				{ name: "Recusado", value: "REFUSED" },
				{ name: "Inativada", value: "INACTIVATED" },
			],
			hasSelect: true,
			translated: translateSolutionStatus,
			width: "50px",
			isReadOnly: true,
		},
		{
			key: "isFavorite",
			kind: "icon",
			icon: "star.svg",
			subMenuStyle: "items-center",
			width: "80px",
			isReadOnly: true,
			hasSelect: true,
			options: [
				{ icon: "star.svg", value: true },
				{ icon: "star.svg", value: false, iconStyle: "filter grayscale" },
			],
		},
		{ key: "name", style: "bold", isReadOnly: true },
	];
	categoryList: any[] = [];
	clientList: { count: number; data: any[]; page: number; pageCount: number; total: number } = {
		count: 0,
		data: [],
		page: 0,
		pageCount: 0,
		total: 0,
	};
	clientSearchParam: string | undefined;
	enterpriseSearchParam: string | undefined;
	solutionInsurerPlansField: any;
	solutionNeedsField: any = {
		key: "solutionNeeds",
		name: "Necessidades",
		kind: "multiselect",
		required: true,
		relationKey: "need",
		formatEntity: true,
		onTransform: this.formatReadOnlyNeed,
	};
	solutionQualificationsField: any = {
		key: "solutionQualifications",
		name: "Qualificações",
		kind: "multiselect",
		relationKey: "qualification",
		shouldShowAddButton: true,
		onAddButtonFunciton: this.openModalNewQualification,
		formatEntity: true,
		onTransform: this.formatReadOnlyQualification,
	};
	enterpriseSolutionsField: any = {
		key: "enterpriseSolutions",
		name: "Benefícios",
		kind: "multiselect",
		relationKey: "enterprise",
		formatEntity: true,
		onTransform: this.formatReadOnlyEnterprise,
		emptyValue: [],
		onSearch: this.debouncedEnterpriseSearch,
		options: [{}],
	};
	isIndividualForClientField: any = {
		key: "isIndividualForClient.id",
		required: false,
		name: "Solução personalizada para cliente",
		kind: "select",
		formatEntity: true,
		options: [{}],
	};
	solutionCorporateClientField: any;

	formColumns: any[] = [{ key: "category", required: true, keyEntity: "category.id" }];

	defaultFormColumns: any[] = [
		{ key: "category", required: true, keyEntity: "category.id" },
		{
			key: "isIndividualForClient.id",
			required: false,
			name: "Solução personalizada para cliente",
			kind: "select",
			options: [{}],
			onSearch: this.debouncedClientSearch,
		},
		{ key: "name", required: true },
		{ key: "description" },
	];
	defaultEntity = {
		"corporateClient.id": [],
	} as any;
	fieldsByActiveInput: any = [];

	creationComponent = {
		component: EntityCrud,
		props: {
			model: this.model,
			title: this.title,
			tableColumns: this.tableColumns,
			formColumns: this.formColumns,
			formStyleVariant: this.formStyleVariant,
			withPostalCode: this.withPostalCode,
			kind: this.kind,
			formContainerVariant: this.formContainerVariant,
			withEntityHeader: this.withEntityHeader,
			withBackButton: false,
			withTitle: false,
			onInputChange: this.onInputChange,
			defaultEntity: this.defaultEntity,
			cleanFormKeys: {
				deleteKeys: ["corporateClient.id", "id", "corporateAndPlans"],
				holdKeys: ["category.id", "isIndividualForClient.id"],
			},
			onLoadingEntity: this.onLoadingEntity,
			isReadOnly: this.isReadOnly,
		},
	};

	openModalNewQualification() {
		this.modalNewQualificationIsOpen = true;
	}

	closedModalNewQualification() {
		this.modalNewQualificationIsOpen = false;
		this.nameNewQualification = "";
	}

	async saveNewQualification() {
		this.isLoading = true;
		try {
			await qualificationModel.create({ name: this.nameNewQualification, icon: "generic-qualification.svg" });
			await this.loadNeedsAndQualifications();
			this.closedModalNewQualification();
		} catch (error) {
			console.error(error);
		} finally {
			this.isLoading = false;
		}
	}

	get currentId() {
		return this.$route.params.id;
	}

	@Watch("currentId")
	changeReadOnly() {
		if (!this.currentId) {
			this.isReadOnly = true;
		}
		if (this.currentId === "novo") {
			this.defaultEntity = {};
			this.formColumns = this.defaultFormColumns;
		}
	}

	async mounted() {
		await this.loadCategory();
		await this.getRelationFields();
		this.debouncedClientSearch = debounce(this.searchClient, 300);
		this.debouncedEnterpriseSearch = debounce(this.searchEnterprise, 300);
	}

	insertCurrentClientOnClientList(currentClient: Partial<Client>) {
		if (
			currentClient &&
			this.defaultFormColumns.filter((field: any) => field.key === "isIndividualForClient.id").length
		) {
			const foundFormColumns = this.defaultFormColumns.find((field: any) => field.key === "isIndividualForClient.id");
			const foundOption = foundFormColumns.options.find((option: any) => option.value === currentClient.id);
			if (!foundOption) {
				foundFormColumns.options.push({
					name: `${currentClient.name} ${currentClient.lastName}`,
					value: currentClient.id,
				});
			}
		}
	}

	async onLoadingEntity(entity: any) {
		this.currentCategoryId = entity["category.id"];
		const currentClient = {
			id: entity["isIndividualForClient.id"],
			name: entity["isIndividualForClient.name"],
			lastName: entity["isIndividualForClient.lastName"],
		};

		this.insertCurrentClientOnClientList(<Client>currentClient);

		this.defaultEntity = {
			"corporateClient.id": entity.solutionInsurerPlans?.insurerPlan?.corporateClient?.id || [],
			...entity,
			"isIndividualForClient.id": currentClient.id ?? undefined,
			"isIndividualForClient.name": currentClient.name ?? undefined,
			"isIndividualForClient.lastName": currentClient.lastName ?? undefined,
		};
		await this.updateFieldsForm();
	}

	async updateFieldsForm() {
		const category = this.categoryList.find(value => value.id == this.currentCategoryId);

		if (!category) {
			return;
		}

		const newFormColumns: any[] = [];
		this.fieldsByActiveInput.forEach((field: any) => {
			if (category[field.key]) {
				field.fields.forEach((newField: any) => {
					if (
						["Consultas", "Exames e Serviços"].includes(category.name) &&
						["solutionInsurerPlans"].includes(newField.key)
					) {
						newField.required = true;
					} else if (newField.key === "corporateClient.id" || newField.key === "solutionInsurerPlans") {
						newField.required = false;
					}
					if (
						!newFormColumns.find(
							fieldColumn => fieldColumn.key === newField.key && fieldColumn.kind === newField.kind,
						)
					) {
						newFormColumns.push(newField);
					}
				});
			}
		});

		this.formColumns = [...this.defaultFormColumns, ...newFormColumns];
		this.creationComponent.props.formColumns = [...this.defaultFormColumns, ...newFormColumns];
	}

	async loadCorporateClients() {
		this.solutionCorporateClientField = {
			key: "corporateClient.id",
			kind: "readOnly",
			name: "Cliente corporativo",
			getInformationOtherKey: true,
			otherKey: "solutionInsurerPlans",
			relationKeyId: "insurerPlan.corporateClient.id",
			relationKeyName: "insurerPlan.corporateClient.name",
			onTransform: this.formatReadOnlyCorporateClient,
		};
	}

	async loadPlans() {
		this.solutionInsurerPlansField = {
			key: "solutionInsurerPlans",
			name: "Planos de saúde",
			relationKey: "insurerPlan",
			required: false,
			formatEntity: true,
			modifyValue: true,
			onTransform: this.formatReadOnlyInsurerPlan,
			kind: "readOnly",
		};
	}

	corporateAndPlansField = {
		key: "corporateAndPlans",
		name: "Clientes corporativos e planos",
		required: false,
		relationKey: "solutionInsurerPlans",
		kind: "table",
		customFormat: true,
		customFunctionFormat: this.createCorporateAndPlans,
		component: CorporateClientAndPlans,
		columns: [
			{
				key: "corporateClient",
				name: "Cliente corporativo",
				kind: "text",
				hasSelect: false,
				onTransform: (corporateClient: any) => corporateClient.name,
			},
			{
				key: "plans",
				name: "Planos",
				kind: "text",
				hasSelect: false,
				onTransform: (plans: any[]) => {
					return plans.reduce(
						(finalPlans: string, currentPlan: { insurerPlan: { id: string; name: string } }, indexPlan: number) => {
							finalPlans = `${finalPlans} ${currentPlan.insurerPlan?.name}${
								indexPlan !== plans.length - 1 && plans.length > 1 ? "," : ""
							}`;
							return finalPlans;
						},
						"",
					);
				},
			},
		],
	};

	createCorporateAndPlans(entity: any) {
		Vue.set(entity, "corporateAndPlans", []);
		entity.corporateAndPlans = [];
		entity.solutionInsurerPlans?.forEach((plan: any) => {
			if (
				!entity.corporateAndPlans.filter(
					(item: any) =>
						item.corporateClient?.id ===
						(plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id),
				).length
			) {
				entity.corporateAndPlans.push({
					corporateClient: {
						id: plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id,
						name: plan["insurerPlan.corporateClient.name"] || plan.insurerPlan?.corporateClient?.name,
					},
					plans: [
						{
							insurerPlan: {
								id: plan["insurerPlan.id"] || plan.insurerPlan?.id,
								name: plan["insurerPlan.name"] || plan.insurerPlan?.name,
								description: plan["insurerPlan.description"] || plan.insurerPlan?.description,
								corporateClient: {
									id: plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id,
									name: plan["insurerPlan.corporateClient.name"] || plan.insurerPlan?.corporateClient?.name,
								},
							},
						},
					],
				});
			} else {
				entity.corporateAndPlans
					.find(
						(item: any) =>
							item.corporateClient?.id === plan["insurerPlan.corporateClient.id"] ||
							plan.insurerPlan?.corporateClient?.id,
					)
					.plans.push({
						insurerPlan: {
							id: plan["insurerPlan.id"] || plan.insurerPlan?.id,
							name: plan["insurerPlan.name"] || plan.insurerPlan?.name,
							description: plan["insurerPlan.description"] || plan.insurerPlan?.description,
							corporateClient: {
								id: plan["insurerPlan.corporateClient.id"] || plan.insurerPlan?.corporateClient?.id,
								name: plan["insurerPlan.corporateClient.name"] || plan.insurerPlan?.corporateClient?.name,
							},
						},
					});
			}
		});
	}

	async loadNeedsAndQualifications() {
		const needs = await needModel.search({});
		const qualifications = await QualificationModel.search({});

		Vue.set(
			this.solutionNeedsField,
			"options",
			needs.map((need: any) => {
				return { name: need.name, value: { need: { id: need.id, name: need.name } } };
			}),
		);

		Vue.set(
			this.solutionQualificationsField,
			"options",
			qualifications.map((qualification: IQualification) => {
				return {
					name: qualification.name,
					value: { qualification: { id: qualification.id, name: qualification.name } },
				};
			}),
		);
	}

	async loadCategory() {
		this.categoryList = await SolutionCategoryModel.search({});
	}

	async loadClients() {
		let params = { page: 1, limit: 50 };
		params = this.clientSearchParam ? Object.assign(params, { searchParam: this.clientSearchParam }) : params;
		this.clientList = await clientModel.search(params);
	}

	async loadEnterpriseSolutions(enterprises?: any) {
		if (!enterprises) {
			const healthInputIndex = this.fieldsByActiveInput.findIndex(
				(field: any) => field.key === "isHealthInputActive",
			);

			if (healthInputIndex === -1) {
				return;
			}

			let params = { page: 1, limit: 50 };
			params = this.enterpriseSearchParam
				? Object.assign(params, { searchParam: this.enterpriseSearchParam })
				: params;
			const enterprises = await enterpriseModel.search(params);
			const currentEnterprises = enterprises.data.map((currentEnterprise: any) => {
				const enterpriseId = currentEnterprise.id;
				const enterpriseName = currentEnterprise.name;
				return {
					name: enterpriseName,
					value: {
						enterprise: { id: enterpriseId, name: enterpriseName },
					},
				};
			});

			const enterpriseSolutionsIndex = this.fieldsByActiveInput[healthInputIndex].fields.findIndex(
				(field: any) => field.key === "enterpriseSolutions",
			);
			currentEnterprises.forEach((enterprise: any) => {
				if (
					!this.fieldsByActiveInput[healthInputIndex].fields[enterpriseSolutionsIndex].options.find(
						(option: any) => option.name === enterprise.name,
					)
				) {
					this.fieldsByActiveInput[healthInputIndex].fields[enterpriseSolutionsIndex].options.push(enterprise);
				}
			});
		}
	}

	searchClient(value: string) {
		this.clientSearchParam = value;
		debounce(this.getRelationFields(false), 300);
	}

	searchEnterprise(value: string) {
		this.enterpriseSearchParam = value;
		debounce(this.getRelationFields(false), 300);
	}

	async getRelationFields(isLoading = true) {
		this.isLoading = isLoading;
		await this.loadClients();
		await this.loadNeedsAndQualifications();
		await this.loadCorporateClients();
		await this.loadPlans();

		this.fieldsByActiveInput = [
			{ key: "isCnpjInputActive", fields: [{ key: "cnpj" }] },
			{ key: "isExamOrDoctorNameInputActive", fields: [{ key: "examOrDoctorName" }] },
			{
				key: "isAddressInputActive",
				fields: [
					{
						key: "isFavorite",
						inputKind: "selectButtons",
						options: [
							{ name: "Sim", value: true },
							{ name: "Não", value: false },
						],
					},
					{
						key: "address.postCode",
						autoComplete: true,
						autoCompleteKeys: ["address.street", "address.neighborhood", "address.city", "address.state"],
					},
					{ key: "address.street" },
					{ key: "address.number" },
					{ key: "address.complement" },
					{ key: "address.neighborhood" },
					{ key: "address.city" },
					{ key: "address.state" },
				],
			},
			{
				key: "isAvailabilityInputActive",
				fields: [{ key: "availabilities", kind: "availabilitySelect", optionsFrom: "availabilities.modality" }],
			},
			{
				key: "isContactInputActive",
				fields: [{ key: "phoneNumber", mask: "tel" }],
			},
			{
				key: "isFileUploadInputActive",
				fields: [{ key: "attachmentUrl" }],
			},
			{
				key: "isGenderInputActive",
				fields: [
					{ key: "gender", onTransform: translateGender },
					{ key: "specialties", kind: "multiselect", onTransform: this.formatReadOnlySpecialties },
				],
			},
			{
				key: "isNeedsInputActive",
				fields: [this.solutionNeedsField],
			},
			{
				key: "isQualificationsInputActive",
				fields: [this.solutionQualificationsField],
			},
			{
				key: "isSpecialtiesKindInputActive",
				fields: [{ key: "specialties", kind: "multiselect", onTransform: this.formatReadOnlySpecialties }],
			},
			{
				key: "isSubcategoryInputActive",
				fields: [{ key: "subcategory" }, { key: "subcategoryAttachmentUrl" }, { key: "subcategoryDescription" }],
			},
			{
				key: "isHealthInputActive",
				fields: [
					this.corporateAndPlansField,
					this.solutionCorporateClientField,
					this.solutionInsurerPlansField,
					this.enterpriseSolutionsField,
				],
			},
		];

		await this.loadEnterpriseSolutions();

		if (!this.tableColumns.filter(column => column.key === "category.name").length) {
			this.tableColumns = [
				...this.tableColumns,
				{
					key: "category.name",
					name: "Categoria",
					kind: "select",
					isReadOnly: true,
					options: this.categoryList.map((category: any) => {
						return { name: category.name, value: category.name };
					}),
				},
			];
		}

		if (this.defaultFormColumns.filter((field: any) => field.key === "isIndividualForClient.id").length) {
			this.defaultFormColumns.find((field: any) => field.key === "isIndividualForClient.id").options =
				this.clientList.data.map((client: any) => {
					return {
						name: `${client.name} ${client.lastName}`,
						value: client.id,
					};
				});
		}

		this.generalLoading = false;
		this.isLoading = false;
	}

	formatReadOnlySpecialties(specialties: any[]) {
		const formatSpecialties = specialties?.reduce((stringSpecialties, currentSpecialties, indexSpecialties) => {
			return `${stringSpecialties} ${translateSpecialties(currentSpecialties)}${
				indexSpecialties !== specialties.length - 1 && specialties.length > 1 ? "," : ""
			}`;
		}, "");

		return formatSpecialties;
	}

	formatReadOnlyNeed(needs: any[]) {
		if (!needs) {
			return;
		}
		const formatNeeds = needs?.reduce((stringNeeds, currentNeed, indexNeed) => {
			return `${stringNeeds} ${currentNeed.need?.name || currentNeed["need.name"]}${
				indexNeed !== needs.length - 1 && needs.length > 1 ? "," : ""
			}`;
		}, "");

		return formatNeeds;
	}

	formatReadOnlyEnterprise(enterprises: any[]) {
		if (!enterprises) {
			return;
		}

		const healthInputIndex = this.fieldsByActiveInput.findIndex((field: any) => field.key === "isHealthInputActive");

		if (healthInputIndex === -1) {
			return;
		}

		const enterpriseSolutionsIndex = this.fieldsByActiveInput[healthInputIndex].fields.findIndex(
			(field: any) => field.key === "enterpriseSolutions",
		);

		enterprises.forEach((enterprise: any) => {
			if (
				!this.fieldsByActiveInput[healthInputIndex].fields[enterpriseSolutionsIndex].options.find(
					(option: any) => option.name === enterprise.enterprise.name,
				)
			) {
				this.fieldsByActiveInput[healthInputIndex].fields[enterpriseSolutionsIndex].options.push(enterprise);
			}
		});

		const formatEnterprises = enterprises?.reduce((stringEnterprises, currentEnterprise, indexEnterprise) => {
			return `${stringEnterprises} ${currentEnterprise.enterprise?.name || currentEnterprise["enterprise.name"]}${
				indexEnterprise !== enterprises.length - 1 && enterprises.length > 1 ? "," : ""
			}`;
		}, "");

		return formatEnterprises;
	}

	formatReadOnlyCorporateClient(corporateClients: any[]) {
		if (!corporateClients) {
			return "";
		}
		const formatCorporateClients = corporateClients?.reduce(
			(stringCorporateClients, currentCorporateClient, indexCorporateClient) => {
				return `${stringCorporateClients} ${currentCorporateClient.name}${
					indexCorporateClient !== corporateClients.length - 1 && corporateClients.length > 1 ? "," : ""
				}`;
			},
			"",
		);

		return formatCorporateClients;
	}

	formatReadOnlyInsurerPlan(plans: any[]) {
		if (!plans) {
			return;
		}
		return plans?.reduce((formattedPlansString, currentPlan, index) => {
			return `${formattedPlansString} ${currentPlan.insurerPlan?.name ?? currentPlan["insurerPlan.name"]}${
				index !== plans.length - 1 && plans.length > 1 ? "," : ""
			}`;
		}, "");
	}

	formatReadOnlyQualification(qualifications: any[]) {
		if (!qualifications) {
			return;
		}
		const formatQualifications = qualifications?.reduce((qualification, currentQualification, indexQualification) => {
			return `${qualification} ${
				currentQualification.qualification?.name || currentQualification["qualification.name"]
			}${indexQualification !== qualifications.length - 1 && qualifications.length > 1 ? "," : ""}`;
		}, "");

		return formatQualifications;
	}

	async setCorporateClientAndReloadRelations(value: string) {
		this.defaultEntity["corporateClient.id"] = value;
		await this.getRelationFields();
	}

	async onInputChange(value: any, fieldKey: any) {
		if (fieldKey === "corporateClient.id") {
			await this.setCorporateClientAndReloadRelations(value);
		}

		if (fieldKey === "category") {
			this.currentCategoryId = value;
		}

		this.updateFieldsForm();
	}

	onBack() {
		this.$router.back();
	}
}


































import { Component, Vue, Prop } from "vue-property-decorator";
import { requireConfirmation, showErrorAlert, showSuccessAlert } from "@/helpers";
import { primaryBody, secondaryBody, tertiaryBoldBody } from "@/helpers/styleClassHelpers";
import EntityForm from "@/views/entity/EntityForm.vue";

@Component({
	components: {},
})
export default class CustomTabs extends Vue {
	@Prop({ default: () => [] }) tabs!: any[];
	@Prop({ default: () => [] }) tabHeaders!: any[];
	@Prop({ default: "" }) selectedHeaderClass!: string;

	primaryBody = primaryBody;
	secondaryBody = secondaryBody;
	tertiaryBoldBody = tertiaryBoldBody;

	entityForm = EntityForm;
	currentEntity = {} as any;
	editItem = {};
	model = {} as any;
	additionalModel = {} as any;
	formEditItem = [] as any;
	formId = this.$route.params.id;
	currentTab = 0;

	entity: any = {};

	buttons = [{ icon: "trash-icon.svg", variant: "tertiary", buttonAction: this.remove }];

	get currentId() {
		return this.$route.params.id;
	}

	toggleCurrentTab(currentTabIndex: any) {
		this.$emit("changeTab", currentTabIndex);
		this.currentTab = currentTabIndex;
	}

	create() {
		this.$router.push(`${this.$router.currentRoute.path}/novo`);
	}

	goBack() {
		this.$router.back();
	}

	deleteEditItem() {
		this.formEditItem[0].remove();
	}

	reloadComponent() {
		this.$emit("onReload");
	}

	async remove() {
		await requireConfirmation("Confirma a exclusão?", "Não será possível reverter esta operação.", "Excluir");

		try {
			await this.model.delete(this.currentEntity.id);
			showSuccessAlert("O registro foi excluído.");
			this.$router.back();
		} catch (error: any) {
			console.error(error);
			showErrorAlert(error?.friendlyMessage || "Ocorreu um erro. Tente novamente.");
		}
	}

	refreshList(entity: any) {
		this.currentEntity.insurerPlans.push(entity);
	}
}
